<template>
  <div v-drag
       class='mydialog'>
    <a-modal v-model:visible="dialogVisible"
             :maskClosable="false"
             width="1050rem"
             @cancel="handleClose"
             style='min-width:300px'
             :bodyStyle="{fontSize:'24rem',padding:'10rem 30rem',height:'630rem',overflow:'scroll' }"
             :footer="null">
      <template #title>
        <div style="font-size:24rem">视频列表</div>
      </template>
      <div class="center_table">
        <div class="table_head">
          <div>
            视频内容
          </div>
          <div>
            操作
          </div>
        </div>
      </div>
      <div class="table_line"
           v-for="(item,index) in list"
           :key="index">
        <div class="two">
          <div>
            {{item.filename}}
          </div>
          <div>
            <a-button type="primary"
                      @click="toDetail(item)">
              视频讲解
            </a-button>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>


<script setup>
import { getKnowledgeByParentid } from '@/api/knowledge.js'
import { useRouter } from 'vue-router'
import { ref } from 'vue'
let router = useRouter()
let dialogVisible = ref(false)
let list = ref([])
// let subjectId = ref('')
const handleClose = () => {
  dialogVisible.value = false
}
const toDetail = (item) => {
  router.push('/course/preview?type=video&url=' + item.url + "&knowledge_desc=" + item.knowledge_desc + '&filename=' + item.filename)
  dialogVisible.value = false
}


defineExpose({
  dialogVisible, list
})


</script>

<style lang="scss" scoped>
.center_table {
  min-width: 300px;
}
.table_head {
  // height: 70rem;
  padding: 20rem 0;
  background: #f6f6f6;
  display: flex;
  align-items: center;
  div {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 24rem;

    color: #666660;
    &:nth-child(1) {
      flex: 3;
      text-indent: 60rem;
    }
    &:nth-child(2) {
      flex: 1;
      text-indent: 103rem;
    }
  }
}
.table_line {
  background: white;
  border-bottom: 1px solid #eaeaea;
  padding: 20rem 0;
  .two {
    display: flex;
    align-items: center;
    margin: 5px 0;
    position: relative;
    div {
      &:nth-child(1) {
        flex: 3;
        text-indent: 60rem;
        padding-right: 70rem;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &:nth-child(2) {
        flex: 1;
        text-indent: 33rem;
      }
    }
  }
}

.ant-btn {
  font-size: 24rem;
  padding: 7rem 20rem;
  border-radius: 5rem;
  height: auto;
}
</style>